import React, { useState, useEffect } from "react";
import { useParams,  useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { actionList } from "../../../data/services/actionlist";
import MovieItemDetail from "../../controls/MovieItemDetail";
import ScreenData from "../../controls/screendata";
import "./index.css";
import { placeParams } from "../../../data/helper";
import { ROUTE_SITE_SHOWDETAIL } from "../../../data/constants/routenames";
import Aux from "../../../hoc/_Aux";

function mapStateToProps(state) {
  return {
    state
  };
}

function Shows() {
  const { id, showdate } = useParams();
  const history = useNavigate();
  const [movieData, setMovieData] = useState(null);
  const [screenData, setScreenData] = useState(null);
  const [theaterName, setTheaterName] = useState(null);

  useEffect(() => {
    getShowList();
  }, []);

  const handleShowClick = (movieid, showID) => {
    let url = placeParams(ROUTE_SITE_SHOWDETAIL, {
      movieid: movieid,
      showID: showID,
    });

    history(url);
  };

  const getShowList = () => {
    let paramObj = {
      showDate: showdate,
      movieid: id,
    };

    actionList.getshowlist(paramObj, async (result) => {
      //;
      let theaterName = "";
      let movieData = result.MovieData;
      let screenData = result.ScreenList;
      setMovieData(movieData);
      setScreenData(screenData);
      setTheaterName(theaterName);
    });
  };

  const handleBackClick = () => {
    history(-1);
  };

  return (
    <Aux>
      <div className="content-wrapper">
        <div className="nav-bar-wrapper col-12">
          <button className="btn btn-secondary" onClick={handleBackClick}>
            Back
          </button>
        </div>
        <div className="movie-wrapper">
          {movieData && <MovieItemDetail movieData={movieData} />}
        </div>
        <div className="screen-data">
          <h2 className="inner-header">
            <span>Viewing Times</span>
          </h2>
          {screenData && (
            <ScreenData
            screenData={screenData}
              movieid={id}
              handleClick={handleShowClick}
            />
          )}
        </div>
      </div>
    </Aux>
  );
}

export default connect(null, mapStateToProps)(Shows);
