import React, { Component } from "react";
import "./index.css";
import { connect } from "react-redux";

const MovieDetailItem = props => {
  //;
  let { movieData, siteData } = props;
  let duration = parseInt(movieData.Duration);
  let durationStr =
    parseInt(duration / 60) +
    " hr " +
    (duration % 60 > 0 ? (duration % 60) + " mins" : "");

  return (
    <div>
      <article className="card card-product-list">
        <div className="row no-gutters">
          <div className="img-wrap">
            {movieData.Grade && <span className="badge badge-danger"> {movieData.Grade} </span>}
            <img
              src={
                siteData.PosterImagePath +
                movieData.PosterImage
              }
              alt={movieData.MovieName}
            />
          </div>

          <div className="col-md-9">
            <div className="info-main movies-info">
              <div className="movies-info">
                <h2>{movieData.MovieName}</h2>
                <h4>{movieData.Genre}</h4>
                <p>{movieData.Synopsis}</p>
                <ul className="movie-cast">
                  <li>
                    <span>Director :&nbsp;</span> {movieData.Director}
                  </li>
                  <li>
                    <span>Cast :&nbsp;</span>
                    {movieData.Cast}
                  </li>
                  <li>
                    <span>Released On :&nbsp;</span> {movieData.ReleaseDate}
                  </li>
                  <li>
                    <span>Duration :&nbsp;</span> {durationStr}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

function mapStateToProps(state) {
  const { cinematixdata } = state;
  return cinematixdata;
}

export default connect(mapStateToProps)(MovieDetailItem);
