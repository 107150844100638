import { toast } from "react-toastify";

import { RemoveDataFromStore } from "../datastore";
import { User_Data, Status_OK } from "../constants/constant";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  ERROR,
  EXCEPTION
} from "../constants/messagetype";
import { redirectToLogin } from "../helper";
import { error } from "jquery";

export function ShowMessage(type, msg) {
  if(msg.code=="ERR_NETWORK"){
    RemoveDataFromStore(User_Data);
    redirectToLogin();
  }
  //;
  var option = {
    position: toast.POSITION.TOP_RIGHT
  };

  switch (type) {
    case LOGIN_SUCCESS:
    case LOGOUT_SUCCESS:
      toast.success(msg, option);
      break;
    case ERROR:
      toast.error(msg, option);
      break;
    case LOGIN_FAILURE:
      toast.error(msg, option);
      redirectToLogin();
      break;
      case EXCEPTION:
        toast.error(msg, option);
        break;
    default:
      break;
  }
}
