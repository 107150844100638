import React from "react";
import MovieItem from "../MovieItem";
import { useSelector } from "react-redux";

const MovieList = ({ movieList,handleClick }) => {
  //const movieList = useSelector(state => state.movieList);
//;
  if (movieList && movieList.length > 0) {
    return (
      <div className="d-flex movie-wrapper">
        {movieList.map((data) => (
          <MovieItem
            movie={data}
            key={data.movieID}
            handleClick={() => handleClick(data.MovieID)}
          />
        ))}
      </div>
    );
  } else {
    return (
      <div className="content-empty">
        <span>No Movies Found !!</span>
      </div>
    );
  }
};

export default MovieList;
