import React, { useEffect, useMemo, Suspense } from "react";
import { connect } from "react-redux";
import { Routes, Route, Outlet } from "react-router-dom";
import { DefaultRoutes, InternalRoutes } from "./routes/index";

import LazyLoader from "./components/controls/lazyloader";
import InternalRoute from "./components/controls/internalRoute/InternalRoute";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./components/controls/header/Header";
import Header from "./components/controls/header/Header";
import MenuBar from "./components/controls/MenuBar";
import { User_Data } from "./data/constants/constant";
import { ROUTE_SITE_LOGIN, ROUTE_SITE_ROOT } from "./data/constants/routenames";
import { GetDataFromStore } from "./data/datastore";

function App() {
  useEffect(() => {
    const userData = GetDataFromStore(User_Data);

    if (userData && userData.user_token && userData.user_token.length > 0 && window.location.pathname === ROUTE_SITE_LOGIN) {
      window.location = ROUTE_SITE_ROOT;
    }
  }, []);

  const dRoutes = useMemo(() =>
    DefaultRoutes.map((route, index) =>
      route.component ? (
        <Route
          key={index}
          path={route.path}
          element={<route.component />}
        />
      ) : null
    )
  , []);

  const iRoutes = useMemo(() =>
  
    InternalRoutes.map((route, index) =>
    
      route.component ? (
        <Route
          key={index}
          path={route.path}
          element={<route.component />}
        />
      ) : null
    )
  , []);

  return (
    <div className="container">
      <Header />
      <MenuBar />
      <Routes>
        <Route path="/" element={<Outlet />}>
          {dRoutes}
          {iRoutes}
        </Route>
      </Routes>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => state;

App = connect(mapStateToProps)(App);

export default App;
