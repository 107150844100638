import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionList } from "../../../data/services/actionlist";
import Logo from "../../../logo.svg";
import {
  ROUTE_SITE_LOGIN,
  ROUTE_SITE_ROOT
} from "../../../data/constants/routenames";
import { User_Data } from "../../../data/constants/constant";
import { GetDataFromStore } from "../../../data/datastore";
import "./header.css";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = GetDataFromStore(User_Data);

  const logoClick = () => {
    navigate(ROUTE_SITE_ROOT);
  };

  const btnClick = () => {
    navigate(ROUTE_SITE_LOGIN);
  };

  const logoutClick = () => {
    
    dispatch(actionList.logout(navigate));
  };

  return (
    <div className="logo-wrapper flex">
      <div className="row">
        <div className="col-8 logo-container">
          <img
            src={Logo}
            className="logo"
            alt="CinemaTix"
            onClick={logoClick}
          />
          <span className="h3">CinemaTix API</span>
        </div>
        {!userData && (
          <div className="col-4 text-right">
            <span className="btn btn-info" onClick={btnClick}>
              Login
            </span>
          </div>
        )}

        {userData && (
          <div className="col-4 text-right align-items-center">
            <div className="row align-items-center">
              <p className="font-italic font-weight-normal col-6 spnUserName">
                Welcome {userData.api_user}
              </p>
              <span className="btn btn-warning col-6" onClick={logoutClick}>
                Logout
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
