import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Aux from "../../../hoc/_Aux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { actionList } from "../../../data/services/actionlist";
import UserTicketList from "../../controls/userticketlist";
import ReactPaginate from "react-paginate";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const MyTicket = () => {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [ticketList, setTicketList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const dataLimit = 10;

  useEffect(() => {
    getTicketList();
  }, [offset, fromDate, toDate]);

  const handleFromChange = async (date) => {
    setFromDate(date);
  };

  const handleToChange = async (date) => {
    setToDate(date);
  };

  const getTicketList = () => {
    const fDate = getDatePart(fromDate);
    const tDate = getDatePart(toDate);
    const paramObj = {
      offset: offset,
      limit: dataLimit,
      fromDate: fDate,
      toDate: tDate,
      username: "chandra",
      transactionCode: "",
    };

    actionList.getMyTickets(paramObj, async (result) => {
      const ticketList = result;
      ;
      const rowTotal = result[0].RowTotal;

      setTicketList(ticketList);
      setPageCount(Math.ceil(rowTotal / dataLimit));
    });
  };

  const getDatePart = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const string = month + "/" + day + "/" + year;
    return string;
  };

  const handlePageClick = (data) => {
    const selected = data.selected;
    const newOffset = Math.ceil(selected * dataLimit);

    setOffset(newOffset);
  };

  return (
    <Aux>
      <div className="content-wrapper">
        <div className="movie-wrapper">
          <div className="row title-wrapper">
            <span className="title col-2">My Tickets</span>
          </div>
          <div className="filter">
            <div className="col-12 text-left">
              <div className="row">
                <span className="col-1">From Date</span>
               <div className="col-2"> <DatePicker
                  selected={fromDate}
                  onChange={handleFromChange}
                />
                </div>
                <span className="col-1">To Date</span>
                <div className="col-2">
                <DatePicker
                  selected={toDate}
                  onChange={handleToChange}
                />
                </div>
              </div>
            </div>
          </div>
          <div className="ticketlist-wrapper">
            {ticketList.length > 0 && (
              <div>
                <UserTicketList ticketList={ticketList} />
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Aux>
  );
};

export default connect(mapStateToProps)(MyTicket);
