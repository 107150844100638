import Home from "../components/views/home/home";
import Login from "../components/views/login/Login";
import Movies from "../components/views/movies/index";
import Shows from "../components/views/shows/index";
import ShowDetail from "../components/views/showdetail/index";
import {
  ROUTE_SITE_ROOT,
  ROUTE_SITE_LOGIN,
  ROUTE_SITE_MOVIELIST,
  ROUTE_SITE_SHOWLIST,
  ROUTE_SITE_SHOWDETAIL,
  ROUTE_SITE_CHECKOUT,
  ROUTE_SITE_MYTICKETS
  
} from "../data/constants/routenames";
import Checkout from "../components/views/checkout";
import MyTickets from "../components/views/mytickets";

export const DefaultRoutes = [
  {
    path: ROUTE_SITE_ROOT,
    exact: true,
    name: "home",
    component: Home
  },
  {
    path: ROUTE_SITE_LOGIN,
    exact: true,
    name: "login",
    component: Login
  }
];

export const InternalRoutes = [
  {
    path: ROUTE_SITE_MOVIELIST,
    exact: true,
    name: "movies",
    component: Movies
  },
  {
    path: ROUTE_SITE_SHOWLIST,
    exact: true,
    name: "shows",
    component: Shows
  }
  ,
  {
    path: ROUTE_SITE_SHOWDETAIL,
    exact: true,
    name: "showdetail",
    component: ShowDetail
  }
  ,
  {
    path: ROUTE_SITE_CHECKOUT,
    exact: true,
    name: "checkout",
    component: Checkout
  },
  {
    path: ROUTE_SITE_MYTICKETS,
    exact: true,
    name: "mytickets",
    component: MyTickets
  }
];
