import React from "react";
import ShowCell from "../showcell";
import "./index.css";

const ScreenData = props => {
  const { screenData, handleClick, movieid } = props;
//;
 // console.log(screenData);

  return (
    <div className="view-show-time">
      {screenData.map((screen, index) => {
        return (
          <div className="audi-time" key={screen.ScreenID}>
            <div className="audi-info">
              <p>
                <span>{screen.ScreenName}</span>
              </p>
            </div>
            <ul className="show-time-info">
              {screen.ShowList.map((show, ind) => {
                return (
                  <li key={show.ShowID}>
                    <ShowCell
                      active={show.IsAvailable}
                      showtime={show.StartTime}
                      key={show.ShowID}
                      handleClick={handleClick.bind(this, movieid, show.ShowID)}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default ScreenData;
