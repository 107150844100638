import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Aux from "../../../hoc/_Aux";
import { actionList } from "../../../data/services/actionlist";
import BookingSummary from "../../controls/bookingsummary";
import "./index.css";
import shortid from "shortid";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_API_URL } from "../../../data/constants/apiroutes";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const Checkout = props => {
  let { siteData } = props;
;
  const { showID } = useParams();
  const history = useNavigate();
  const [selectedSeats, setSelectedSeats] = useState(null);
  const [isPaymentComplete, setPaymentComplete] = useState(false);
  const [paymentObj, setPaymentObj] = useState(null);

  useEffect(() => {
    getSelectedTicketList();
  }, []);

  const handleBackClick = () => {
    history(-1);
  };

  useEffect(() => {
    if (selectedSeats && selectedSeats.length === 0) {
      history(-1);
    }
  }, [selectedSeats]);

  const getSelectedTicketList = () => {
    const paramObj = {
      showID,
      username: "chandra",
    };

    actionList.getselectedseat(paramObj, (result) => {
      setSelectedSeats(result);
    });
  };

  const onPurchaseClick = () => {
    if (selectedSeats) {
      const transactionCode = shortid.generate().substring(0, 12);

      let seatIds = selectedSeats.map((seat) => seat.SeatID).join(",");

      const total = selectedSeats.reduce(
        (previousValue, currentValue) => previousValue + currentValue.Price,
        0
      );

      const paramObj = {
        showID,
        seatIDs: seatIds,
        username: "chandra",
        contactNo: "9801160059",
        paidAmount: total,
        paymentCode: transactionCode,
      };

      actionList.purchaseticket(paramObj, (result) => {
        console.log(result);
        if (result !== null) {
          const paymentObj = {
            TransactionCode: result.TransactionCode,
            PaymentCode: result.PaymentCode,
            TicketFilePath:  ROUTE_API_URL+ result.TicketFilePath,
          };

          setPaymentObj(paymentObj);
          setPaymentComplete(true);
        }
      });
    }
  };

  return (
    <Aux>
      <div className="no-gutter content-wrapper">
        <div className="nav-bar-wrapper col-12">
          <span className="btn btn-secondary" onClick={handleBackClick}>
            Back
          </span>
        </div>
        {!isPaymentComplete && (
          <div>
            <h3>Booking Summary</h3>
            <div className="d-flex">
              {selectedSeats && (
                <BookingSummary selectedSeats={selectedSeats} />
              )}

              <div className="col-6">
                <div className="purchase-container">
                  <button
                    className="btn btn-info btnPurchase"
                    onClick={onPurchaseClick}
                  >
                    Purchase
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isPaymentComplete && (
          <div>
            <h3>Purchase Summary</h3>
            <div className="d-flex">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  Transaction Code: {paymentObj.TransactionCode}
                </li>
                <li className="list-group-item">
                  Payment Code: {paymentObj.PaymentCode}
                </li>
                <li className="list-group-item">
                  <a href={paymentObj.TicketFilePath} target="_blank">
                    Download Ticket
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </Aux>
  );
};

export default connect(mapStateToProps)(Checkout);
