import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { actionList } from "../../../data/services/actionlist";
import "./index.css";
import Aux from "../../../hoc/_Aux";
import SeatLayout from "../../controls/screenlayout";
import { ShowMessage } from "../../../data/message/showMessage";
import { ERROR } from "../../../data/constants/messagetype";
import { ROUTE_SITE_CHECKOUT } from "../../../data/constants/routenames";
import { placeParams } from "../../../data/helper";

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const ShowDetail = () => {
  //;
  const navigate = useNavigate();
  const { movieid, showID } = useParams();

  const [ticketTotal, setTicketTotal] = useState(0.0);
  const [seatList, setSeatList] = useState(null);
  const [seatTypeList, setSeatTypeList] = useState(null);
  const [screenData, setScreenData] = useState("");

  const handleSeatClick = async (seatid, selected) => {
    const paramObj = {
      showID,
      username: "chandra",
      seatID: seatid,
      isSelected: !selected,
    };

    actionList.getseatselect(paramObj, async (result) => {
      //;
      const updatedSeatList = result.SeatList;
      const ticketPrice = result.Price;
      let updatedTicketTotal = ticketTotal;

      if (!selected) {
        updatedTicketTotal += ticketPrice;
      } else {
        updatedTicketTotal -= ticketPrice;
      }

      if (!result.IsComplete) {
        ShowMessage(ERROR, "Sorry, the process could not be completed");
      }

      setSeatList(updatedSeatList);
      setTicketTotal(updatedTicketTotal);
    });
  };

  const clearSeatSelection = async () => {
    const paramObj = {
      showID,
      username: "chandra",
    };

    actionList.getseatclear(paramObj, async (result) => {
      const updatedSeatList = result.SeatList;
      setSeatList(updatedSeatList);
    });
  };

  const handleBackClick = () => {
    clearSeatSelection();
    navigate(-1);
  };

  useEffect(() => {
    clearSeatSelection();
    getShowDetail();
  }, []);

  const getShowDetail = () => {
    const paramObj = {
      showID,
      movieID: movieid,

      username: "chandra",
    };

    actionList.getshowdetail(paramObj, async (result) => {
      const updatedScreenData = {
        screenID: result.ScreenID,
        screenName: result.ScreenName,
        movie: result.MovieName,
        theater: "Cinema Tix",
        status: result.IsAvailable,
        showDate: result.ShowDate,
        showTime: result.StartTime,
      };

      const updatedSeatList = result.SeatList;
      const updatedSeatTypeList = result.SeatTypeList;

      setScreenData(updatedScreenData);
      setSeatList(updatedSeatList);
      setSeatTypeList(updatedSeatTypeList);
    });
  };

  const onProceedClick = () => {
    if (ticketTotal > 0) {
      const url = placeParams(ROUTE_SITE_CHECKOUT, {
        showID,
      });
      navigate(url);
    }
  };

  return (
    <Aux>
      <div className="content-wrapper">
        <div className="nav-bar-wrapper col-12">
          <span className="btn btn-secondary" onClick={handleBackClick}>
            Back
          </span>
        </div>
        {screenData && (
          <div className="booking-details col-12">
            <div className="row">
              <div className="movie-detail-wrapper col-6">
                <div className="booking-title-wrapper">
                  <h3 className="movieName">{screenData.movie}</h3>
                  <p>({screenData.theater})</p>
                </div>
                <span className="booking-audi">{screenData.screenName}</span>
                <span className="booking-date">{screenData.showDate}</span>
                <span className="booking-time">{screenData.showTime}</span>
              </div>
              <div className="content-right d-flex justify-content-end col-6">
                <div className="row">
                  <div className="sfGrandTotal">
                    <h5>Total: </h5>
                    <h5 className="spanGrandTotal">Rs. {ticketTotal}</h5>
                  </div>
                  <div className="sfProceedButton">
                    <span className="btn btn-info" onClick={onProceedClick}>
                      Proceed
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {seatList && (
          <SeatLayout
            seatList={seatList}
            seatTypeList={seatTypeList}
            handleClick={handleSeatClick}
          />
        )}
      </div>
    </Aux>
  );
};

export default connect(mapStateToProps)(ShowDetail);
