export const ROUTE_API_URL = "https://paymentapi.technables.com/"
export const ROUTE_API_USERTOKEN = "GetAPIToken";
export const ROUTE_API_SITESETTING = "GetTheatreSetting";
export const ROUTE_API_MOVIELIST = "MovieList";
export const ROUTE_API_SHOWLIST = "GetShows";
export const ROUTE_API_SHOWDETAIL = "GetShowDetail";
export const ROUTE_API_SELECTSEAT = "ManageSeatSelection";
export const ROUTE_API_SELECTEDSEATLIST = "GetSeatListForUser";
export const ROUTE_API_CLEARSELECTION = "ClearSelectionForUser";
export const ROUTE_API_PURCHASETICKET = "PurchaseTicket";
export const ROUTE_API_USERTICKETLIST = "TicketList";