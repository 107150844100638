import React from "react";
import "./index.css";
import { connect } from "react-redux";

const MovieItem = props => {
  let { movie, siteData, handleClick } = props;
  let duration = parseInt(movie.Duration);
  //;
  let durationStr =
    parseInt(duration / 60) +
    " hr " +
    (duration % 60 > 0 ? (duration % 60) + " mins" : "");

  return (
    <div className="col-md-3 col-sm-6 movieItem" onClick={handleClick}>
      <figure className="card card-product-grid">
        <div className="img-wrap">
          <img
            src={
              siteData.PosterImagePath + movie.PosterImage
            }
            alt={movie.MovieName}
          />
        </div>
        <figcaption className="info-wrap border-top">
          <span className="title">{movie.MovieName}</span>
          <span className="spn-duration">{durationStr}</span>
        </figcaption>
      </figure>
    </div>
  );
};

function mapStateToProps(state) {
  const { cinematixdata } = state;
  return cinematixdata;
}

export default connect(mapStateToProps)(MovieItem);
