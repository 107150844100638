import React from "react";
import { connect } from "react-redux";

import { NavLink } from "react-router-dom";
import {
  ROUTE_SITE_ROOT,
  ROUTE_SITE_MOVIELIST,
  ROUTE_SITE_MYTICKETS
} from "../../../data/constants/routenames";
import { GetDataFromStore } from "../../../data/datastore";
import { User_Data } from "../../../data/constants/constant";

class MenuBar extends React.Component {
  render() {
    const userData = GetDataFromStore(User_Data);
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <NavLink className="nav-item nav-link" to={ROUTE_SITE_ROOT}>
              Home
            </NavLink>
            {userData && userData.user_token && userData.user_token.length > 0 && (
              <div className="navbar-nav">
                <NavLink
                  className="nav-item nav-link "
                  to={ROUTE_SITE_MOVIELIST}
                >
                  Movies
                </NavLink>
                <NavLink
                  className="nav-item nav-link "
                  to={ROUTE_SITE_MYTICKETS}
                >
                  My Tickets
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(MenuBar);
