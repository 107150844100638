import React, { Component } from "react";
import "./index.css";

class LoginControl extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { handleClick } = this.props;

    return (
      <div className="row login-container">
        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div className="card card-signin my-5">
            <div className="card-body">
              <h5 className="card-title text-center">Sign In</h5>
              <form className="form-signin">
                <div className="form-label-group">
                  <input
                    type="text"
                    id="username"
                    className="form-control"
                    placeholder="Username"
                    required
                    autoFocus
                    onChange={e => this.setState({ username: e.target.value })}
                  />
                  <label htmlFor="username">Username</label>
                </div>

                <div className="form-label-group">
                  <input
                    type="password"
                    id="inputPassword"
                    className="form-control"
                    placeholder="Password"
                    required
                    onChange={e => this.setState({ password: e.target.value })}
                  />
                  <label htmlFor="inputPassword">Password</label>
                </div>
                <div className="form-label-group">
                  <input
                    type="password"
                    id="inputClientSecret"
                    className="form-control"
                    placeholder="Client Secret"
                    required
                    onChange={e => this.setState({ secret: e.target.value })}
                  />
                  <label htmlFor="inputClientSecret">Client Secret</label>
                </div>

                <input
                  type="button"
                  className="btn btn-lg btn-primary btn-block text-uppercase"
                  value="Sign In"
                  onClick={handleClick.bind(
                    this,
                    this.state.username,
                    this.state.password,
                    this.state.secret
                  )}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginControl;
