import EndPoints from "../endpoints";
import axios from "axios";
import { GetDataFromStore } from "../datastore";
import { User_Data } from "../constants/constant";

export const services = {
  login,
  siteSetting,
  movieList,
  showlist,
  showdetail,
  selectSeat,
  clearSeat,
  selectedseatlist,
  purchaseTicket,
  myTickets
};

const endPoint = EndPoints.CinemaTixAPI;

async function login(obj, api_secret, history) {

  let axiosConfig = {
    headers: {
      post: {
        "Content-Type": "application/json;",
        "Access-Control-Allow-Origin": "*",
        "Api_secret":api_secret
      }
    }
  };

  var response = null;
  //;
  response = await axios.post(endPoint.USER_TOKEN, obj, axiosConfig);
console.log(response);
  return response;
}

async function siteSetting() {
  let axiosConfig = getHeader();

  var response = null;
  let obj = {};
  response = await axios.post(endPoint.SITE_SETTING, obj, axiosConfig);

  return response;
}

async function movieList(date) {
  let axiosConfig = getHeader();

  var response = null;
  let obj = { showDate: date };
  response = await axios.post(endPoint.MOVIE_LIST, obj, axiosConfig);

  return response;
}

async function showlist(param) {
  let axiosConfig = getHeader();

  var response = null;
  let obj = param;
  response = await axios.post(endPoint.SHOW_LIST, obj, axiosConfig);

  return response;
}

async function showdetail(param) {
  let axiosConfig = getHeader();

  var response = null;
  let obj = param;
  response = await axios.post(endPoint.SHOW_DETAIL, obj, axiosConfig);

  return response;
}

async function clearSeat(param) {
  let axiosConfig = getHeader();

  var response = null;
  let obj = param;
  response = await axios.post(endPoint.CLEAR_SELECTION, obj, axiosConfig);

  return response;
}

async function selectSeat(param) {
  let axiosConfig = getHeader();
  var response = null;
  let obj = param;
  response = await axios.post(endPoint.SELECT_SEAT, obj, axiosConfig);

  return response;
}

async function selectedseatlist(param) {
  let axiosConfig = getHeader();
  var response = null;
  let obj = param;
  response = await axios.post(endPoint.SELECTED_SEATLIST, obj, axiosConfig);

  return response;
}

async function purchaseTicket(param) {
  let axiosConfig = getHeader();
  var response = null;
  let obj = param;
  response = await axios.post(endPoint.PURCHASE_TICKET, obj, axiosConfig);

  return response;
}

async function myTickets(param) {
  let axiosConfig = getHeader();
  var response = null;
  let obj = param;
  response = await axios.post(endPoint.USER_TICKETLIST, obj, axiosConfig);

  return response;
}

function getHeader() {
  var userData = GetDataFromStore(User_Data);

  let axiosConfig = {
    headers: {
      post: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${userData.user_token}`
      }
    }
  };

  return axiosConfig;
}
