import { services } from "../services";

import { ShowMessage } from "../../message/showMessage";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  EXCEPTION,
  LOGOUT_SUCCESS,
  SITE_SETTING,
} from "../../constants/messagetype";
import {
  ROUTE_SITE_ROOT,
  ROUTE_SITE_MOVIELIST,
} from "../../constants/routenames";
import { RemoveDataFromStore } from "../../datastore";
import { User_Data, Status_OK } from "../../constants/constant";
export const actionList = {
  login,
  logout,
  getmovielist,
  getshowlist,
  getshowdetail,
  getseatselect,
  getseatclear,
  getselectedseat,
  purchaseticket,
  getMyTickets,
};

function login(obj, api_secret, history) {
  return (dispatch) => {
    services.login(obj, api_secret, history).then(
      (response) => {
        if (response) {
          if (response.data) {
            let dataObj = response.data;
            if (dataObj.StatusCode === Status_OK) {
              dispatch(
                LoginSuccess(dataObj.BearerToken, api_secret, obj.Username)
              );

              services.siteSetting().then(
                (resp) => {
                  if (resp) {
                    if (resp.data) {
                      let respObj = resp.data;
                      if (respObj.StatusCode === Status_OK) {
                        let siteSettingObj = respObj.Data;
                        dispatch(SiteSettingSuccess(siteSettingObj));
                        history(ROUTE_SITE_MOVIELIST);
                      } else {
                        ShowMessage(LOGIN_FAILURE, dataObj.message);
                      }
                    }
                  }
                },
                (err) => {
                  ShowMessage(EXCEPTION, err);
                }
              );

              ShowMessage(LOGIN_SUCCESS, "User Loggged In Successfully");
              history(ROUTE_SITE_ROOT);
            } else {
              ShowMessage(LOGIN_FAILURE, dataObj.message);
            }
          }
        }
      },
      (err) => {
        ShowMessage(EXCEPTION, err.response.data);
      }
    );
  };
}

function logout(history) {
  return (dispatch) => {
    //;
    RemoveDataFromStore(User_Data);
    dispatch(LogoutSuccess());
    ShowMessage(LOGOUT_SUCCESS, "User Loggged Out Successfully");

    history(ROUTE_SITE_ROOT);
  };
}

function getmovielist(date, bindingResult) {
  services.movieList(date).then(
    (response) => {
      if (response) {
        if (response.data) {
          let dataObj = response.data;
          if (dataObj.StatusCode === Status_OK) {
            var resp = null;
            resp = dataObj.Data;

            if (bindingResult) bindingResult(resp);
          } else {
            RemoveDataFromStore(User_Data);
            ShowMessage(LOGIN_FAILURE, dataObj.message);
          }
        }
      }
    },
    (err) => {
      ShowMessage(EXCEPTION, err);
    }
  );
}

function getshowlist(obj, bindingResult) {
  services.showlist(obj).then(
    (response) => {
      if (response) {
        if (response.data) {
          let dataObj = response.data;
          if (dataObj.StatusCode === Status_OK) {
            var resp = null;
            resp = dataObj.Data;

            if (bindingResult) bindingResult(resp);
          } else {
            RemoveDataFromStore(User_Data);
            ShowMessage(LOGIN_FAILURE, dataObj.message);
          }
        }
      }
    },
    (err) => {
      ShowMessage(EXCEPTION, err);
    }
  );
}

function getseatclear(obj, bindingResult) {
  services.clearSeat(obj).then(
    (response) => {
      if (response) {
        if (response.data) {
          let dataObj = response.data;
          if (dataObj.StatusCode === Status_OK) {
            var resp = null;
            resp = dataObj.Data;

            if (bindingResult) bindingResult(resp);
          } else {
            RemoveDataFromStore(User_Data);
            ShowMessage(LOGIN_FAILURE, dataObj.message);
          }
        }
      }
    },
    (err) => {
      ShowMessage(EXCEPTION, err);
    }
  );
}

function getshowdetail(obj, bindingResult) {
  //;
  services.showdetail(obj).then(
    (response) => {
      if (response) {
        //;
        if (response.data) {
          let dataObj = response.data;
          if (dataObj.StatusCode === Status_OK) {
            var resp = null;
            resp = dataObj.Data;

            if (bindingResult) bindingResult(resp);
          } else {
            RemoveDataFromStore(User_Data);
            ShowMessage(LOGIN_FAILURE, dataObj.message);
          }
        }
      }
    },
    (err) => {
      ShowMessage(EXCEPTION, err);
    }
  );
}

function getseatselect(obj, bindingResult) {
  services.selectSeat(obj).then(
    (response) => {
      if (response) {
        if (response.data) {
          let dataObj = response.data;
          if (dataObj.StatusCode === Status_OK) {
            var resp = null;
            resp = dataObj.Data;

            if (bindingResult) bindingResult(resp);
          } else {
            RemoveDataFromStore(User_Data);
            ShowMessage(LOGIN_FAILURE, dataObj.message);
          }
        }
      }
    },
    (err) => {
      ShowMessage(EXCEPTION, err);
    }
  );
}

function getselectedseat(obj, bindingResult) {
  services.selectedseatlist(obj).then(
    (response) => {
      if (response) {
        if (response.data) {
          let dataObj = response.data;
          if (dataObj.StatusCode === Status_OK) {
            var resp = null;
            resp = dataObj.Data;

            if (bindingResult) bindingResult(resp);
          } else {
            RemoveDataFromStore(User_Data);
            ShowMessage(LOGIN_FAILURE, dataObj.message);
          }
        }
      }
    },
    (err) => {
      ShowMessage(EXCEPTION, err);
    }
  );
}

function purchaseticket(obj, bindingResult) {
  services.purchaseTicket(obj).then(
    (response) => {
      if (response) {
        if (response.data) {
          let dataObj = response.data;
          if (dataObj.StatusCode === Status_OK) {
            var resp = null;
            resp = dataObj.Data;

            if (bindingResult) bindingResult(resp);
          } else {
            RemoveDataFromStore(User_Data);
            ShowMessage(LOGIN_FAILURE, dataObj.message);
          }
        }
      }
    },
    (err) => {
      ShowMessage(EXCEPTION, err);
    }
  );
}

function getMyTickets(obj, bindingResult) {
  services.myTickets(obj).then(
    (response) => {
      if (response) {
        if (response.data) {
          let dataObj = response.data;
          if (dataObj.StatusCode === Status_OK) {
            var resp = null;
            resp = dataObj.Data;

            if (bindingResult) bindingResult(resp);
          } else {
            RemoveDataFromStore(User_Data);
            ShowMessage(LOGIN_FAILURE, dataObj.message);
          }
        }
      }
    },
    (err) => {
      ShowMessage(EXCEPTION, err);
    }
  );
}

function LoginSuccess(usertoken, app_secret, api_user) {
  return {
    type: LOGIN_SUCCESS,
    data: { token: usertoken, app_secret, api_user },
  };
  //return { type: GAME_INIT };
}

function LogoutSuccess() {
  return { type: LOGOUT_SUCCESS };
}

function SiteSettingSuccess(siteConfig) {
  return { type: SITE_SETTING, data: siteConfig };
}
