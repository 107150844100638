import {
  GetDataFromStore,
  SaveDataToStore,
  RemoveDataFromStore
} from "../datastore";
import { User_Data, Site_Data } from "../constants/constant";
import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SITE_SETTING
} from "../constants/messagetype";

export const initialState = {
  movieid: 0,
  showid: 0,
  currentUser: GetDataFromStore(User_Data),
  siteData: GetDataFromStore(Site_Data),
  success: 0,
  user_token: "",
  app_secret: ""
};

const cinematixdata = (state = initialState, action) => {
  switch (action.type) {
    case "Root":
      var userStore = GetDataFromStore(User_Data);
      return {
        ...state,
        movieid: 0,
        showid: 0,
        currentUser: userStore
      };
    case LOGIN_SUCCESS:
      var user = {
        user_token: action.data.token,
        app_secret: action.data.app_secret,
        api_user: action.data.api_user
      };

      SaveDataToStore(User_Data, user);

      return {
        ...state,
        currentUser: user
      };

    case SITE_SETTING:
      SaveDataToStore(Site_Data, action.data);
      return {
        ...state,
        siteData: action.data
      };
    case LOGOUT_SUCCESS:
      RemoveDataFromStore(User_Data);
      return {
        ...state,
        currentUser: null
      };

    default: {
      return state;
    }
  }
};

export default cinematixdata;
