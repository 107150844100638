import React from "react";
import './index.css'

const ShowCell = props => {
  const { active, showtime, handleClick } = props;

  

  if (active) {
    return (
      <button className={`show-time active`} onClick={handleClick}>
        {showtime}
      </button>
    );
  } else {
    return <span className={`show-time no-available`}>{showtime}</span>;
  }
};

export default ShowCell;
