import React, { Component } from "react";
import "./index.css";

const UserTicketList = props => {
  const { ticketList } = props;
  return (
    <div>
      <div className="content-wrapper">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th className="th-lg">Movie</th>
                <th className="th-lg">Show Detail</th>
                <th className="th-lg">Transaction Detail</th>
                <th className="th-lg">Action</th>
              </tr>
            </thead>
            <tbody>
              {ticketList.map((ticket, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{ticket.RowNum}</th>
                    <td>{ticket.MovieName}</td>
                    <td>
                      <div className="row">
                        <span className="ticket-row-content">
                          <span className="key">Screen :</span>
                          <p className="value">{ticket.ScreenName}</p>
                        </span>
                        <span className="ticket-row-content">
                          <span className="key">Show :</span>
                          <p className="value">
                            {ticket.showDate} {ticket.ShowTime}
                          </p>
                        </span>
                        <span className="ticket-row-content">
                          <span className="key">Seats :</span>
                          <p className="value">{ticket.SeatNos}</p>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="row">
                      <span className="ticket-row-content">
                          <span className="key">Amount :</span>
                          <p className="value">Rs. {parseFloat(ticket.PaidAmount).toFixed(2)}</p>
                        </span>
                        <span className="ticket-row-content">
                          <span className="key">Transaction Date :</span>
                          <p className="value">{ticket.TransactionDate}</p>
                        </span>
                        <span className="ticket-row-content">
                          <span className="key">Transaction Code :</span>
                          <p className="value">
                             {ticket.TransactionCode}
                          </p>
                        </span>
                        <span className="ticket-row-content">
                          <span className="key">Payment Code :</span>
                          <p className="value">{ticket.PaymentCode}</p>
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href={ticket.ticketUrl} target="_blank">
                        Download
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserTicketList;
