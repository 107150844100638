import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { actionList } from "../../../data/services/actionlist";
import MovieList from "../../controls/MovieList";
import "./index.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { ROUTE_SITE_SHOWLIST } from "../../../data/constants/routenames";
import { placeParams } from "../../../data/helper";
import Aux from "../../../hoc/_Aux";

function Movies(props) {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [movieList, setMovieList] = useState([]);
  const [theaterName, setTheaterName] = useState("Cinema Tix");

  useEffect(() => {
    getMovieList();
  }, [startDate]);

  const getMovieList = () => {
    const formattedDate = formatDate(startDate);

    actionList.getmovielist(formattedDate, async (result) => {
      //const theaterName = result.theaterName;
      const movieList = result;

      setMovieList(movieList);
      //setTheaterName(theaterName);
    });
  };

  const handleChange = (date) => {
    setStartDate(date);
  };

  const handleMovieClick = (movieId) => {
    const formattedDate = formatDate(startDate);

    const url = placeParams(ROUTE_SITE_SHOWLIST, {
      id: movieId,
      showdate: formattedDate,
    });

    navigate(url);
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  return (
    <Aux>
      <div>
        <div className="content-wrapper">
          <div className="filter"></div>
          <div className="movie-wrapper">
            <div className="row title-wrapper">
              <span className="title col-4">{theaterName}</span>
              <div className="col-8 text-right">
                <div className="row">
                  <span className="col-3">Select Date</span>
                  <div className="col-9">
                    <DatePicker
                      selected={startDate}
                      minDate={new Date()}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <MovieList movieList={movieList} handleClick={handleMovieClick} />
          </div>
        </div>
      </div>
    </Aux>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(Movies);
