import React, { Component, useState } from "react";
import { connect } from "react-redux";
import "./index.css";

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

const ScreenLayout = props => {
  const { seatList, seatTypeList, handleClick } = props;

  const [seatTypeID, setSeatTypeID] = useState(-1)

  const seatArray = seatList.reduce(
    (
      columnSoFar,
      {
        SeatRow,
        SeatID,
        SeatNo,
        RowName,
        SeatColumn,
        SeatTypeID,
        BookingStatus,
        IsSeat
      }
    ) => {
      if (!columnSoFar[SeatRow]) columnSoFar[SeatRow] = [];
      columnSoFar[SeatRow].push({
        SeatRow,
        SeatID,
        SeatNo,
        RowName,
        SeatColumn,
        SeatTypeID,
        BookingStatus,
        IsSeat
      });
      return columnSoFar;
    },
    []
  );

  return (
    <div className="seatPlan col-12">
      <div className="ta-seatPlanGrid col-12">
        <table className="tblSeatArrange col-12">
          <tbody>
            {seatArray.map((row, index) => {
              return (
                <tr key={index}>
                  {row.map(seat => {
                    let extraClass = "";
                    let isClickable = false;
                    let isSelected = false;
                    switch (seat.BookingStatus) {
                      case -2:
                        extraClass = "taBlockedSeat";
                        isClickable = false;
                        break;
                      case 0:
                        extraClass = "taAvailable";
                        isClickable = true;
                        break;
                      case 1:
                        extraClass = "taBookedSeat";
                        break;
                      case 2:
                        extraClass = "taSoldSeat";
                        break;
                      case 4:
                        extraClass = "taSelectedSeat";
                        isClickable = true;
                        isSelected = true;
                        break;
                      default:
                        extraClass = "";
                    }
                    return (
                      
                      <td className="ta-seat" key={seat.SeatID}>
                        {seat.IsSeat && (
                          <span
                            className={`cineSeats ${extraClass}`}
                            onClick={
                              isClickable
                                ? handleClick.bind(
                                    this,
                                    seat.SeatID,
                                    isSelected
                                  )
                                : null
                            }
                          >
                            {seat.SeatNo}
                          </span>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="sfScreenLocation col-12">Screen</div>
    </div>
  );
};

export default connect(mapStateToProps)(ScreenLayout);
