import {
  ROUTE_API_URL,
  ROUTE_API_USERTOKEN,
  ROUTE_API_SITESETTING,
  ROUTE_API_MOVIELIST,
  ROUTE_API_SHOWLIST,
  ROUTE_API_SHOWDETAIL,
  ROUTE_API_SELECTSEAT,
  ROUTE_API_SELECTEDSEATLIST,
  ROUTE_API_CLEARSELECTION,
  ROUTE_API_PURCHASETICKET,
  ROUTE_API_USERTICKETLIST
} from "../constants/apiroutes";

export default {
  CinemaTixAPI: {
      USER_TOKEN : GenerateAccountURI(ROUTE_API_USERTOKEN),
      SITE_SETTING : GenerateAccountURI(ROUTE_API_SITESETTING),
      MOVIE_LIST : GenerateURI(ROUTE_API_MOVIELIST),
      SHOW_LIST : GenerateURI(ROUTE_API_SHOWLIST),
      SHOW_DETAIL : GenerateURI(ROUTE_API_SHOWDETAIL),
      SELECT_SEAT : GenerateURI(ROUTE_API_SELECTSEAT),
      SELECTED_SEATLIST : GenerateURI(ROUTE_API_SELECTEDSEATLIST),
      CLEAR_SELECTION : GenerateURI(ROUTE_API_CLEARSELECTION),
      PURCHASE_TICKET : GenerateURI(ROUTE_API_PURCHASETICKET),
      USER_TICKETLIST : GenerateURI(ROUTE_API_USERTICKETLIST),
  }
};

function GenerateAccountURI(uri) {
  return ROUTE_API_URL + "api/v1/Authenticate/" + uri;
}


function GenerateURI(uri) {
  return ROUTE_API_URL + "api/v1/Movie/" + uri;
}
