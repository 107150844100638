import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logincontrol from "../../controls/logincontrol";
import { actionList } from "../../../data/services/actionlist";
import Aux from "../../../hoc/_Aux";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (username, password, secret) => {
    if (username && password && secret) {
      const obj = {
        Username: username,
        Password: password,
      };
      dispatch(actionList.login(obj, secret, navigate));
    }
  };

  return (
    <Aux>
      <Logincontrol handleClick={handleClick} />
    </Aux>
  );
};

export default Login;
