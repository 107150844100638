import { ROUTE_SITE_LOGIN, ROUTE_SITE_ROOT } from "./constants/routenames";

export function placeParams(pathRegex, params) {
  var segments = pathRegex.split("/");

  //;
  return segments
    .map(segment => {
      var offset = segment.indexOf(":") + 1;
      if (!offset) return segment;

      var key = segment.slice(offset);
      return params[key];
    })
    .join("/");
}

export function redirectToLogin() {
  window.location = ROUTE_SITE_LOGIN;
}
