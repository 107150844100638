import React, { Component } from "react";
import "./index.css";

const BookingSummary = props => {
  let ticketTotal = 0;
  const seatHtml = props.selectedSeats.map((seat, index) => {
    ticketTotal += seat.Price;
    return (
      <li className="list-group-item seat-wrapper " key={seat.SeatID}>
        <span className="col-6">{seat.SeatNo}</span>
        <span className="col-6 ">Rs. {seat.Price}</span>
      </li>
    );
  });
  return (
    <div className="col-6">
      <ul className="list-group col-12 list-group-flush">
        <li className="list-group-item seat-wrapper li-title">
          <span className="col-6">Seat No</span>
          <span className="col-6 ">Price</span>
        </li>
        {seatHtml}
        <li className="list-group-item seat-wrapper li-title">
          <span className="col-6">Total</span>
          <span className="col-6">Rs. {ticketTotal}</span>
        </li>
      </ul>
    </div>
  );
};

export default BookingSummary;
