import React from "react";
import { User_Data } from "../../../data/constants/constant";
import { ROUTE_SITE_LOGIN } from "../../../data/constants/routenames";
import { useNavigate } from "react-router-dom";
import "./home.css";
import { connect } from "react-redux";
import Aux from "../../../hoc/_Aux";

function Home(props) {
  const history = useNavigate();

  const onLoginClick = () => {
    localStorage.setItem(User_Data, JSON.stringify({ user: "chandra" }));
    history(ROUTE_SITE_LOGIN);
  };

  const CurlOpen = "{";
  const CurlClose = "}";

  return (
    <Aux>
      <div>
        <div className="row"></div>
        <div className="container-body">
          <h3>Welcome to CinemaTix API</h3>
          <div className="content-info">
            <p></p>
          </div>

          <div className="api-list">
            <p>Click <a href="https://documenter.getpostman.com/view/7949818/2s9Xy5NrQL" target="_blank"> here</a> to view the documentation.</p>
           
          </div>
        </div>

        <div className="content-footer">
          <p className="font-weight-normal h5">
            Implement these APIs and get a fully-fledged movie app :)
          </p>
        </div>
      </div>
    </Aux>
  );
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(Home);
